<template>
  <cart-page />

  <pop-up-form @closeModal="closeBlockModal">
    <div class="popup-container">
      <div class="popup-title">Благодарим за оформление заказа</div>
      <div class="btn-action display--flex">
        <router-link to="/" type="button" class="btn btn-o">
          Перейти на главную
        </router-link>
      </div>
    </div>
  </pop-up-form>
</template>

<script>
import { onBeforeMount } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import PopUpForm from "../../components/pop-up-form/PopUpForm.vue";
import CartPage from "../cart-page/CartPage.vue";
import axios from "axios";
import { useStore } from "vuex";
export default {
  components: { CartPage, PopUpForm },
  setup() {
    const router = useRouter(),
      route = useRoute(),
      store = useStore();
    let closeBlockModal = () => {
      router.push("/");
    };

    onBeforeMount(async () => {
      await axios(
        `${process.env.VUE_APP_API_URL}/orders/${route.params.id}/confirm/${route.params.secret_code}`
      )
        .then(() => {
          // eslint-disable-next-line no-undef
          ym(61610965,'reachGoal','order');
          localStorage.removeItem("cartProducts");
          localStorage.removeItem("basketCount");
          localStorage.removeItem("generalPrice");
          localStorage.removeItem("product");
          store.commit("products/setBasketProducts", null);
        })
        .catch(() => {
          router.push("/fail-payment");
        });
    });

    return {
      closeBlockModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.popup-container {
  margin-right: 35px;
}
.popup-title {
  margin-bottom: 30px;
  font-size: 46px;
  line-height: 51px;

  @media (max-width: 767px) {
    font-size: 36px;
    line-height: 43px;
  }
}
</style>